
/**
 * @name: basic
 * @author: lili
 * @date: 2023-06-01 11:21
 * @description：营销管理-专题活动-添加，修改  基础信息
 * @update: 2023-06-01 11:21
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Message} from "element-ui";
import config from "@/config";
import {ICourse} from "@/apis/coursesManage/courses/types";
import {queryTourLocationListApi} from "@/apis/travelManage";
import {existingCoachApi} from "@/apis/coursesManage/courses";
import {IThematicQuery} from "@/apis/marketingManage/thematicActivities/types";
import {
  queryContestListApi, queryLinkActivityListApi,
  queryThematicActivitiesQueryContestListListApi
} from "@/apis/marketingManage/thematicActivities";
import {querySkipContentListApi} from "@/apis/marketingManage/banner";

@Component({})
export default class ProductBasic extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: IThematicQuery  ;
  // 新增修改表单rules
  @Prop(Object) rules!: any;


  contestList: any = []

  linkActivityType:any=[{label: '商品', value: 1}, {label: '旅游', value:2},{label: '赛事', value:3},{label: '视频课程', value:4},{label: '网红陪玩', value:5},{label: '1v1教练课程', value:6}];

  activeContentList: any = []
  activeContentId: any =''


  @Watch('modelForm')
  onChangeValue(newVal: any, oldVal: any) {
    if (newVal) {
      this.onChangeActiveTypes();
      this.$nextTick(()=>{
        this.activeContentId =newVal.contestId;
      })
    }
  }
  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * IUpload
   * 文件提交，成功或失败
   * @param res
   */
  onFileUpload(res: any) {
    if (res) {
    }
  }


  /**
   * 保存
   */
  handleSave() {
    if(!this.activeContentId||this.activeContentId==''){
      return Message.error("请选择活动！");
    }
    this.$set(this.modelForm,'contestId',this.activeContentId)
    this.$set(this.modelForm,'linkActivityName',this.activeContentList.find((e:any)=>e.value==this.activeContentId).label)
    this.$emit('Finish', this.modelForm)
  }

  /**
   * 专题类型切换
   */
  onChangeActiveTypes(){
    this.activeContentId =''
    if (!this.modelForm.linkActivityType){
      return
    }
    queryLinkActivityListApi(this.modelForm.linkActivityType).then(e=> {
      let list = e as [];
      this.activeContentList = list.map((e: any) => {
        return {
          label: e.linkActivityName,
          value: e.contestId+''
        }
      }) as any
    })
  }
  /**
   * 专题类型切换
   */
  onChangeActiveValue(){
    this.$set(this.modelForm,'contestId',this.activeContentId)
  }

  getList(){
    queryThematicActivitiesQueryContestListListApi({status:1}).then(e => {
      this.contestList = e
    })

  }

  created() {
    console.log('created------')
    this.onChangeActiveTypes();
    // this.getList();
  }
}
